<template>
    <div class="media-library-thumb" dusk="thumb">
        <!-- Image files with working src -->
        <img
            v-if="!!imgProps.src && !imageErrored && imgProps.isImage"
            :src="imgProps.src"
            class="media-library-thumb-img"
            @error="imageErrored = true"
        />
        
        <!-- PDF preview - showing thumbnail image for PDFs -->
        <div v-else-if="!!imgProps.src && !imageErrored && imgProps.isPdf" 
            class="media-library-thumb-pdf"
        >
            <!-- Show the PDF icon with overlay -->
            <div class="pdf-icon">
                <img 
                    :src="imgProps.src"
                    class="media-library-thumb-img"
                    @error="imageErrored = true"
                    style="object-fit: contain; max-height: 100px;"
                />
                <div class="pdf-overlay">PDF</div>
            </div>
        </div>
        
        <!-- Fallback for errors or non-image files -->
        <span v-else class="media-library-thumb-extension">
            <span class="media-library-thumb-extension-truncate">{{ imgProps.extension.toUpperCase() }}</span>
        </span>

        <uploader
            v-bind="$attrs"
            :validation-rules="validationRules"
            :add="false"
            :multiple="false"
            :upload-info="uploadInfo"
            @dropped="$emit('replaced', $event.dataTransfer.files[0])"
            @changed="$emit('replaced', $event.target.files[0])"
        />
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { Uploader } from '@spatie/media-library-pro-vue3';

const props = defineProps({
    uploadInfo: { required: true, type: Object },
    validationRules: { required: false, type: Object },
    imgProps: { required: true, type: Object },
});

const emit = defineEmits(['replaced']);

const imageErrored = ref(false);
const oldImgSrc = ref(props.imgProps?.src || '');

// Reset error state when src changes
watch(() => props.imgProps?.src, (newSrc, oldSrc) => {
    if (newSrc !== oldSrc) {
        imageErrored.value = false;
        oldImgSrc.value = newSrc;
    }
}, { immediate: true, deep: true });

// Debug output for PDF rendering
watch(() => props.imgProps, (newProps) => {
    if (newProps?.isPdf) {
        console.log("PDF props received:", newProps);
    }
}, { immediate: true, deep: true });
</script>

<style>
.media-library-thumb-pdf {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pdf-icon {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pdf-overlay {
    position: absolute;
    bottom: 5px;
    right: 5px;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 10px;
    font-weight: bold;
}
</style>
